import React from "react";
import ReactDOM from "react-dom/client";
import { UserProvider } from "components/UserContext";
import App from "App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/simulateur-soregies\.pages\.dev/,
    /^https:\/\/dev\.simulateur-soregies\.pages\.dev/,
    /^https:\/\/staging\.simulateur-soregies\.pages\.dev/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// import your route components too
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserProvider>
      <Routes>
        <Route index element={<App />} />
        <Route path="*" element={<App />}></Route>
        {/* <Route path="*" element={<App id='Q1' />} /> */}
      </Routes>
    </UserProvider>
  </BrowserRouter>,
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
