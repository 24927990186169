// const perduPhoto = 'https://hellio.com/hubfs/simulateur/images/perduPhoto.png';
// const amenagePhoto = 'https://hellio.com/hubfs/simulateur/images/amenagePhoto.png';
import perduPhoto from "../images/perduPhoto.png";
import amenagePhoto from "../images/amenagePhoto.png";
import reglementairePhoto from "../images/reglementairePhoto.png";
import incitatifPhoto from "../images/incitatifPhoto.png";

import { ReactComponent as maison } from "images/icons/maison.svg";
import { ReactComponent as chauffage } from "images/icons/chauffage.svg";
import { ReactComponent as renovation } from "images/icons/renovation.svg";
import { ReactComponent as solaires } from "images/icons/solaires.svg";
import { ReactComponent as recharge } from "images/icons/recharge.svg";
import { ReactComponent as audit } from "images/icons/audit.svg";
import { ReactComponent as question } from "images/icons/question.svg";
import { ReactComponent as appartement } from "images/icons/appartement.svg";
import { ReactComponent as tempsA } from "images/icons/tempsA.svg";
import { ReactComponent as tempsB } from "images/icons/tempsB.svg";
import { ReactComponent as tempsC } from "images/icons/tempsC.svg";
import { ReactComponent as isolation1 } from "images/icons/isolation1.svg";
import { ReactComponent as isolation2 } from "images/icons/isolation2.svg";
import { ReactComponent as isolation3 } from "images/icons/isolation3.svg";
import { ReactComponent as isolation4 } from "images/icons/isolation4.svg";
import { ReactComponent as q5a } from "images/icons/q5a.svg";
import { ReactComponent as q5b } from "images/icons/q5b.svg";
import { ReactComponent as mur1 } from "images/icons/mur1.svg";
import { ReactComponent as mur2 } from "images/icons/mur2.svg";
import { ReactComponent as q7a } from "images/icons/q7a.svg";
import { ReactComponent as q7b } from "images/icons/q7b.svg";
import { ReactComponent as q7c } from "images/icons/q7c.svg";
import { ReactComponent as q7d } from "images/icons/q7d.svg";
import { ReactComponent as oui } from "images/icons/oui.svg";
import { ReactComponent as non } from "images/icons/non.svg";
import { ReactComponent as kwA } from "images/icons/3kw.svg";
import { ReactComponent as kwB } from "images/icons/7kw.svg";
import { ReactComponent as kwC } from "images/icons/11kw.svg";
import { ReactComponent as kwD } from "images/icons/22kw.svg";
import { ReactComponent as borneA } from "images/icons/borneDIstanceA.svg";
import { ReactComponent as borneB } from "images/icons/borneDIstanceB.svg";
import { ReactComponent as borneC } from "images/icons/borneDIstanceC.svg";
import { ReactComponent as borneD } from "images/icons/borneDIstanceD.svg";
import { ReactComponent as borneE } from "images/icons/borneDIstanceE.svg";
import { ReactComponent as localPro } from "images/icons/localpro.svg";
import { ReactComponent as localProApt } from "images/icons/localproapt.svg";
import { ReactComponent as contratA } from "images/icons/contratA.svg";
import { ReactComponent as contratB } from "images/icons/contratB.svg";
import { ReactComponent as autres } from "images/icons/autres.svg";
// import { ReactComponent as copro } from "images/icons/copro.svg";
import { ReactComponent as gaz } from "images/icons/gaz.svg";
import { ReactComponent as fioul } from "images/icons/fioul.svg";
import { ReactComponent as electricite } from "images/icons/electricite.svg";
import { ReactComponent as pompe } from "images/icons/pompe.svg";
import { ReactComponent as bois } from "images/icons/bois.svg";
import { ReactComponent as panneaux } from "images/icons/panneaux.svg";
import { ReactComponent as charbon } from "images/icons/charbon.svg";
import { ReactComponent as occupant } from "images/icons/occupant.svg";
import { ReactComponent as nonOccupant } from "images/icons/non-occupant.svg";
import { ReactComponent as bailleur } from "images/icons/bailleur.svg";
import { ReactComponent as locataire } from "images/icons/locataire.svg";
import { ReactComponent as fioulA } from "images/icons/fioulA.svg";
import { ReactComponent as fioulB } from "images/icons/fioulB.svg";
import { ReactComponent as fioulC } from "images/icons/fioulC.svg";
import { ReactComponent as electricA } from "images/icons/electricA.svg";
import { ReactComponent as electricB } from "images/icons/electricB.svg";
import { ReactComponent as electricC } from "images/icons/electricC.svg";
import { ReactComponent as radiateurGaz } from "images/icons/radiateurGaz.svg";
import { ReactComponent as poeleGaz } from "images/icons/poeleGaz.svg";
import { ReactComponent as temps1 } from "images/icons/temps1.svg";
import { ReactComponent as temps2 } from "images/icons/temps2.svg";
import { ReactComponent as temps3 } from "images/icons/temps3.svg";
import { ReactComponent as temps4 } from "images/icons/temps4.svg";
import { ReactComponent as adviceA } from "images/icons/adviceA.svg";
import { ReactComponent as adviceB } from "images/icons/adviceB.svg";
import { ReactComponent as adviceC } from "images/icons/adviceC.svg";
import { ReactComponent as newsletterA } from "images/icons/newsletterA.svg";
import { ReactComponent as newsletterB } from "images/icons/newsletterB.svg";
import { ReactComponent as charbonA } from "images/icons/charbonA.svg";
import { ReactComponent as charbonB } from "images/icons/charbonB.svg";
import { ReactComponent as menuiserie } from "images/icons/menuiserie.svg";
import { ReactComponent as plomberie } from "images/icons/plomberie.svg";
import { ReactComponent as ventilation } from "images/icons/ventilation.svg";
import { ReactComponent as maconnerie } from "images/icons/maconnerie.svg";
import { ReactComponent as amenagementINT } from "images/icons/amenagementINT.svg";
import { ReactComponent as auditA } from "images/icons/auditA.svg";
import { ReactComponent as auditB } from "images/icons/auditB.svg";
import { ReactComponent as fenetreA } from "images/icons/fenetreA.svg";
import { ReactComponent as fenetreB } from "images/icons/fenetreB.svg";
import { ReactComponent as chauffeEau } from "images/icons/chauffeEau.svg";
import { ReactComponent as gaz1 } from "images/icons/gaz1.svg";
import { ReactComponent as gaz2 } from "images/icons/gaz2.svg";

const dataItems = {
  Q1: {
    greeting: "Bonjour, quel projet souhaitez vous réaliser ?",
    // 'paragraph': 'Vous pouvez sélectionner une ou plusieurs réponses.',
    items: [
      // { id: 1, short: "isolation", long: "Isoler mon logement", icon: maison },
      // { id: 1, short: "isolation", long: "Isoler mes fênetres", icon: maison },
      // { id: 2, short: "chauffage", long: "Changer mon chauffage", icon:chauffage},
      // { id: 3, short: "renovationGlobale", long: "Rénovation globale", icon:renovation },
      // { id: 4, short: "photovoltaïque", long: "Installer des panneaux solaires", icon: solaires},
      // { id: 5, short: "borneDeRecharge", long: "Borne de recharge", icon: recharge},
      // { id: 6, short: "auditEnergetique", long: "Audit énergétique", icon: audit},
      // { id: 7, short: "primeHellio", long: "J'ai déjà un devis et je veux une prime", icon: adviceB },
      // { id: 8, short: "nonRenseigne", long: "J’hésite encore, je souhaite être conseillé", icon: question},
      // { id: 9, short: "chauffeEau", long: "Changer mon chauffe-eau", icon: chauffeEau},

      { id: 1, short: "isolation", long: "Isoler mes fênetres", icon: maison },
      {
        id: 2,
        short: "chauffage",
        long: "Changer mon chauffage",
        icon: chauffage,
      },
      {
        id: 9,
        short: "chauffeEau",
        long: "Installer un chauffe-eau thermodynamique",
        icon: chauffeEau,
      },
    ],
  },

  Q2: {
    greeting: "Avez-vous une maison ou un appartement ?",
    paragraphEauEau:
      "La prime Sorégies pour la pompe à chaleur eau/eau n'est éligible que pour les maisons individuelles",
    items: [
      { short: "Maison individuelle", long: "Maison", icon: maison },
      { short: "Appartement", long: "Appartement", icon: appartement },
    ],
  },

  Q3: {
    greeting: "Votre logement a été construit il y a :",
    items: [
      { short: "Moins de 2 ans", long: "Moins de 2 ans", icon: tempsA },
      { short: "Entre 2 et 15 ans", long: "Entre 2 et 15 ans", icon: tempsB },
      { short: "Plus de 15 ans", long: "Plus de 15 ans", icon: tempsC },
    ],
  },
  Q4: {
    greeting: "Que souhaitez-vous isoler ?",
    paragraph:
      " Si vous souhaitez isoler votre garage ou sous-sol, ceux-ci ne doivent pas être chauffés.",
    items: [
      {
        short: "Isolation de combles ou de toitures",
        long: "Des combles",
        icon: isolation1,
      },
      { short: "Isolation des murs", long: "Des murs", icon: isolation2 },
      { short: "garage", long: "Un garage ou sous-sol", icon: isolation3 },
      { short: "vide", long: "Un vide sanitaire", icon: isolation4 },
    ],
  },
  Q5: {
    greeting: "Les combles de votre maison sont :",
    minHeight: "350px",
    items: [
      {
        short: "Perdus",
        long: "Perdus",
        icon: q5a,
        img: perduPhoto,
        text: "L’isolation se fait au sol - Les combles perdus sont aussi appelés combles non aménageables ou combles visitables. C'est un espace sous la toiture difficile, voire impossible à aménager à cause d’une hauteur libre moyenne insuffisante, ou d’une mauvaise disposition des bois de la charpente.",
      },
      {
        short: "Aménagés",
        long: "Aménagés",
        icon: q5b,
        img: amenagePhoto,
        text: "L’isolation se fait au plafond - Les combles aménagés sont connus sous plusieurs autres appellations comme les combles accessibles ou les combles habitables. Ils désignent des combles qui peuvent être aménagés en lieux de vie, une pièce à part entière de la maison. ",
      },
      // { short: "Non renseigné", long: "Je ne sais pas", icon: question },
    ],
  },

  Q6: {
    greeting: "Vous souhaitez isoler vos murs :",
    items: [
      { short: "ITE", long: "Par l’extérieur", icon: mur1 },
      { short: "ITI", long: "Par l’intérieur", icon: mur2 },
    ],
  },

  Q7: {
    greeting:
      "Avez-vous une préférence pour le nouveau chauffage de votre logement ?",
    items: [
      {
        short: "Pompe à chaleur air/eau",
        long: "Pompe à chaleur air/eau",
        icon: q7a,
      },
      { short: "Chaudière gaz", long: "Chaudière à gaz", icon: q7b },
      {
        short: "Chaudière biomasse",
        long: "Chaudière à granulés de bois",
        icon: q7c,
      },
      // { short: "Poêle à bois", long: "Poêle à bois", icon: q7d },
      {
        short: "Non renseigné",
        long: "Je n’ai pas de préférences",
        icon: question,
      },
    ],
  },

  Q8: {
    greeting: "Quelle est la surface de votre logement ?",
    paragraph: "Veuillez saisir la surface en m²",
    items: [
      {
        label: "Surface (m²)",
        name: "Q8",
        placeholder: "150",
        type: "number",
        min: 10,
        step: 10,
      },
    ],
  },

  Q9: {
    greeting:
      "Avez-vous déjà votre véhicule électrique ou hybride rechargeable ?",
    items: [
      { short: "true", long: "Oui", icon: oui },
      { short: "false", long: "Non", icon: non },
    ],
  },

  Q10: {
    greeting: "Quel est le modèle de votre voiture ?",
    items: [
      { label: "Modèle", name: "Q10", placeholder: "Citroën C4", type: "text" },
    ],
  },
  Q11: {
    greeting:
      "Connaissez-vous la puissance de recharge dont vous avez besoin ?",
    paragraph: "kW = Kilowatt",
    items: [
      { short: "3 kW", long: "3 kW", icon: kwA },
      { short: "7 kW", long: "7 kW", icon: kwB },
      { short: "11 kW", long: "11 kW", icon: kwC },
      { short: "22 kW", long: "22 kW", icon: kwD },
      { short: "Je ne sais pas", long: "Je ne sais pas", icon: question },
    ],
  },
  Q12: {
    greeting:
      "Quelle est la distance entre votre tableau électrique et l'emplacement souhaité pour votre borne de recharge ?",
    items: [
      {
        short: "Entre 0 et 10 mètres",
        long: "Entre 0 et 10 mètres",
        icon: borneA,
      },
      { short: "15 mètres", long: "15 mètres", icon: borneB },
      { short: "20 mètres", long: "20 mètres", icon: borneC },
      { short: "25 mètres", long: "25 mètres", icon: borneD },
      { short: "+ 25 mètres", long: "+ de 25 mètres", icon: borneE },
      { short: "Je ne sais pas", long: "Je ne sais pas ", icon: question },
    ],
  },

  Q13: {
    greeting:
      "Dans quel type de local ou logement doit être installer votre borne de recharge ?",
    items: [
      { short: "Maison individuelle", long: "Une maison", icon: maison },
      { short: "Appartement", long: "Un appartement", icon: appartement },
      {
        short: "Local professionnel",
        long: "Un local professionnel",
        icon: localPro,
      },
      {
        short: "Local professionnel appartement",
        long: "Un local professionnel en appartement",
        icon: localProApt,
      },
    ],
  },

  Q14: {
    greeting: "Avez-vous réalisé un audit énergétique pour ce logement ?",
    paragraph:
      "Un audit énergétique permet d'évaluer la performance énergétique actuelle du logement et d'établir les scénarios de rénovation énergétique.",
    items: [
      { short: "Oui", long: "Oui", icon: oui },
      { short: "Non", long: "Non", icon: non },
    ],
  },

  Q15: {
    greeting:
      "Merci, quel est le montant de votre facture mensuelle d’électricité en euros ? ",
    paragraph:
      "Cela nous permettra de calculer vos économies d’énergie afin de baisser votre facture d’électricité.",
    items: [
      {
        label: "Montant",
        name: "Q15",
        placeholder: "89",
        type: "number",
        unit: "€",
        min: 10,
        step: 10,
      },
    ],
  },

  Q16: {
    greeting: "Quel est le type de contrat électrique que vous avez ?",
    items: [
      { short: "Base", long: "Contrat de base", icon: contratA },
      {
        short: "Heures pleines heures creuses",
        long: "Contrat heure creuse / heure pleine",
        icon: contratB,
      },
      { short: "Autre", long: "Autres", icon: autres },
    ],
  },

  Q17: {
    greeting: "Votre projet de rénovation concerne :",
    items: [
      {
        short: "Logement individuelle",
        long: "Votre logement individuel",
        icon: maison,
      },
      { short: "Copropriété", long: "La copropriété", icon: appartement },
    ],
  },

  Q18: {
    greeting: "Pouvez-vous remplir les coordonnées de votre syndic :",
    paragraph: "Tous les champs sont obligatoires.",
    items: [
      {
        label: "Prénom",
        name: "syndicFirstname",
        placeholder: "Pierre",
        type: "text",
      },
      {
        label: "Nom",
        name: "syndicLastname",
        placeholder: "Roche",
        type: "text",
      },
      {
        label: "Téléphone",
        name: "syndicMobilephone",
        placeholder: "0146821239",
        type: "tel",
        validation: "phone",
        maxLength: 10,
        minLength: 10,
      },
      {
        label: "E-mail",
        name: "syndicEmail",
        placeholder: "pierre.roche@gmail.com",
        type: "email",
        validation: "email",
      },
    ],
    salutation: [
      { label: "Civilité", name: "syndicSalutation", value: "Madame" },
      { label: "Civilité", name: "syndicSalutation", value: "Monsieur" },
    ],
  },

  Q19: {
    greeting: "Quels sont les revenus de votre ménage ?",
    greetingSide: true,
    paragraph:
      "Ces informations nous permettront de vous donner la couleur de votre ménage MaPrimeRénov' ",
  },

  Q20: {
    greeting: "Dans votre logement, vous êtes ?",
    items: [
      {
        short: "Propriétaire occupant",
        long: "Propriétaire occupant",
        icon: occupant,
      },
      {
        short: "Propriétaire non-occupant",
        long: "Propriétaire non-occupant",
        icon: nonOccupant,
      },
      {
        short: "Propriétaire bailleur",
        long: "Propriétaire bailleur",
        icon: bailleur,
        tooltip: true,
      },
      { short: "Locataire", long: "Locataire", icon: locataire },
    ],
  },

  Q21: {
    greeting:
      "En tant que propriétaire bailleur, quelle est l’adresse de votre résidence principale ?",
    paragraph:
      "Les aides à la rénovation énergétique varient en fonction de votre localisation.",
  },

  Q22: {
    greeting: "Quelle est l’énergie de chauffage actuelle de votre logement ?",
    items: [
      { short: "Gaz", long: "Gaz", icon: gaz },
      { short: "Fioul", long: "Fioul", icon: fioul },
      { short: "Electricité", long: "Électricité", icon: electricite },
      { short: "Pompe à chaleur", long: "Pompe à chaleur", icon: pompe },
      { short: "Bois", long: "Bois", icon: bois },
      // { short: "Solaire thermique", long: "Solaire thermique", icon: panneaux },
      { short: "Charbon", long: "Charbon", icon: charbon },
      // { short: "Je ne suis pas chauffé(e)", long: "Je ne suis pas chauffé(e)", icon: non },
    ],
  },

  "Q22-solaire": {
    greeting: "Quelle est l’énergie de chauffage actuelle de votre logement ?",
    items: [
      { short: "Gaz", long: "Gaz", icon: gaz },
      { short: "Fioul", long: "Fioul", icon: fioul },
      { short: "Charbon", long: "Charbon", icon: charbon },
    ],
  },

  "Q22-pachybride": {
    greeting: "Quelle est l’énergie de chauffage actuelle de votre logement ?",
    items: [
      { short: "Gaz", long: "Gaz", icon: gaz },
      { short: "Fioul", long: "Fioul", icon: fioul },
      { short: "Charbon", long: "Charbon", icon: charbon },
    ],
  },

  Q23: {
    greeting: "Quel type d'équipement au fioul est installé ?",
    // 'paragraph': "Les chaudières installées depuis moins de 10 ans sont généralement à condensation",
    items: [
      // { short: "Chaudière à fioul à condensation", long: "Chaudière à condensation", icon: fioulA },
      {
        short: "Chaudière à fioul à basse température",
        long: "Chaudière",
        icon: fioulB,
      },
      { short: "Poêle fioul", long: "Poêle", icon: fioulC },
    ],
  },

  Q24: {
    greeting: "Quel type d'équipement électrique est installé ?",
    items: [
      {
        short: "Chaudière électrique",
        long: "Chaudière électrique",
        icon: electricA,
      },
      {
        short: "Plafonds ou plancher chauffants",
        long: "Plafond ou plancher chauffant",
        icon: electricB,
      },
      {
        short: "Radiateurs électriques",
        long: "Radiateurs électriques",
        icon: electricC,
      },
    ],
  },

  Q25: {
    greeting: "Quel type d'équipement au gaz est installé ?",
    // 'paragraph': "Les chaudières installées depuis moins de 10 ans sont généralement à condensation",
    items: [
      // { short: "Chaudière gaz à condensation", long: "Chaudière à condensation", icon: fioulA },
      { short: "Chaudière gaz classique", long: "Chaudière", icon: fioulB },
      { short: "Radiateur gaz", long: "Radiateur au gaz", icon: radiateurGaz },
      { short: "Poêle au gaz", long: "Poêle au gaz", icon: poeleGaz },
    ],
  },

  Q26: {
    greeting: "Merci, quelle est l’adresse du logement pour votre projet ?",
    paragraph: "Tous les champs sont obligatoires.",
  },

  Q27: {
    greeting: "Quelle est votre adresse fiscale ?",
    paragraph: "Tous les champs sont obligatoires.",
  },

  Q28: {
    greeting:
      "Parfait ! Sous combien de temps souhaitez-vous réaliser ces travaux ?",
    items: [
      { short: "Dès que possible", long: "Dès que possible", icon: temps1 },
      { short: "Moins de 3 mois", long: "Moins de 3 mois", icon: temps2 },
      { short: "Moins de 6 mois", long: "Moins de 6 mois", icon: temps3 },
      { short: "Plus de 6 mois", long: "Plus de 6 mois", icon: temps4 },
    ],
  },

  Q29: {
    greeting:
      "Merci, maintenant apprenons à nous connaître. <br/> Pouvez-vous remplir vos coordonnées :",
    paragraph: "Tous les champs sont obligatoires.",
    paragraphUser: "Vos informations ont été récupérées depuis votre compte.",
    items: [
      {
        label: "Prénom",
        name: "firstname",
        placeholder: "Pierre",
        type: "text",
      },
      {
        label: "Nom",
        name: "lastname",
        placeholder: "Roche",
        type: "text",
      },
      {
        label: "Téléphone",
        name: "mobilephone",
        placeholder: "0146821239",
        type: "tel",
        validation: "phone",
        maxLength: 255,
        minLength: 5,
      },
      {
        label: "E-mail",
        name: "email",
        placeholder: "pierre.roche@gmail.com",
        type: "email",
        validation: "email",
      },
    ],
    salutation: [
      { label: "Civilité", name: "salutation", value: "Madame" },
      { label: "Civilité", name: "salutation", value: "Monsieur" },
    ],
  },

  Q30: {
    greeting: "Quel type d'accompagnement souhaitez-vous ?",
    items: [
      // { short: "comparaisonDevis", long: "Je veux comparer mon devis", icon: adviceA },
      {
        short: "primeHellio",
        long: "J'ai déjà un devis et je veux une prime Sorégies",
        icon: adviceB,
      },
      {
        short: "simulationTravaux",
        long: "Je veux simuler le coût de mes travaux",
        icon: adviceC,
      },
    ],
  },

  Q31: {
    greeting: "Avez-vous un code partenaire ou parrain ?",
    items: [
      { short: "true", long: "Oui", icon: oui },
      { short: "false", long: "Non", icon: non },
    ],
  },

  Q32: {
    greeting: "Remplissez votre code partenaire ou parrain :",
    items: [{ label: "Code", name: "Q32", placeholder: "BDICC3000" }],
  },

  Q33: {
    greeting: "Souhaitez-vous recevoir la newsletter Hellio ?",
    paragraph:
      "Restez informé(e) sur les économies d'énergies et les aides à la rénovation.",
    items: [
      {
        short: "Oui, 1 fois par mois",
        long: "Oui, 1 fois par mois",
        icon: newsletterA,
      },
      {
        short: "Oui, 1 fois par trimestre",
        long: "Oui, 1 fois par trimestre",
        icon: newsletterB,
      },
      {
        short: "Je ne suis pas intéressé(e)",
        long: "Je ne suis pas intéressé",
        icon: non,
      },
    ],
  },

  Q34: {
    greeting: "Quel type d'équipement au charbon est installé ?",
    items: [
      { short: "Chaudière à charbon", long: "Chaudière", icon: charbonA },
      { short: "Autre", long: "Poêle ou autre", icon: charbonB },
    ],
  },

  Q35: {
    greeting: "Quels travaux de rénovation envisagez-vous ?",
    paragraph: "Vous pouvez sélectionner plusieurs postes de travaux",
    items: [
      { short: "isolation", long: "Isolation", icon: maison },
      { short: "chauffage", long: "Chauffage", icon: chauffage },
      { short: "menuiserie", long: "Menuiserie", icon: menuiserie },
      { short: "ventilation", long: "Ventilation", icon: ventilation },
      { short: "maçonnerie", long: "Maçonnerie", icon: maconnerie },
      { short: "plomberie", long: "Plomberie", icon: plomberie },
      { short: "électricité", long: "Électricité", icon: electricite },
      {
        short: "aménagement int",
        long: "Aménagement d’intérieur",
        icon: amenagementINT,
      },
    ],
  },

  Q36: {
    greeting: "Qu’est ce qu’une rénovation globale ?",
    content:
      "À ne pas confondre avec les travaux de peinture, maçonnerie, plomberie…, la rénovation énergétique globale désigne un ensemble de travaux coordonnés pour réaliser un maximum d’économies d’énergie",
    list: [
      "Travaux préconisés par un bilan énergétique en amont",
      "Gain de confort optimal pour les occupants",
      "Projet éligible à des financements plus importants qu’en réalisant les travaux un par un",
    ],
  },

  Q37: {
    greeting: "Pourquoi faire réaliser un audit énergétique ?",
    content:
      "Ce bilan permet de déterminer les travaux de rénovation globale les plus efficaces, avec la garantie d’atteindre un certain niveau de performance énergétique.",
    list: [
      "Le professionnel calcule les économies d’énergie réelles et les caractéristiques thermiques du logement",
      "Primes disponibles sans condition de ressources : un atout pour les ménages aux revenus intermédiaires ou supérieurs",
      "Ouvre droit à un maximum d’aides financières pour rénover : l’investissement est facilement rentabilisé",
    ],
  },

  Q38: {
    greeting: "",
    title: "Votre inscription a bien été pris en compte.",
    paragraph:
      "Un expert Hellio vous rappelera dans les plus brefs délais pour donner suite à votre projet.",
  },

  Q39: {
    greeting: "Que souhaitez-vous isoler ?",
    paragraph:
      " Si vous souhaitez isoler votre garage ou sous-sol, celui-ci ne doit pas être chauffé.",
    items: [
      {
        short: "Isolation de combles ou de toitures",
        long: "Des combles",
        icon: isolation1,
      },
      { short: "Isolation des murs", long: "Des murs", icon: isolation2 },
      { short: "garage", long: "Un garage ou sous-sol", icon: isolation3 },
      { short: "vide", long: "Un vide sanitaire", icon: isolation4 },
    ],
  },

  Q40: {
    greeting:
      "Avez-vous une préférence pour le nouveau chauffage de votre logement ?",
    items: [
      { short: "Pompe à chaleur air/eau", long: "Pompe à chaleur", icon: q7a },
      { short: "Chaudière gaz", long: "Chaudière à gaz", icon: q7b },
      {
        short: "Chaudière biomasse",
        long: "Chaudière à granulés de bois",
        icon: q7c,
      },
      // { short: "Poêle à bois", long: "Poêle à bois", icon: q7d },
      {
        short: "Non renseigné",
        long: "Je n’ai pas de préférences",
        icon: question,
      },
    ],
  },

  Q43: {
    greeting: "Quel est le montant de votre devis ?",
    items: [
      {
        label: "Montant des travaux",
        name: "Q43",
        placeholder: "150",
        type: "number",
        min: 10,
        step: 10,
      },
    ],
  },

  Q44: {
    greeting: "Quel est la surface à isoler ?",
    paragraph:
      "Le coût et les aides de vos travaux sont calculés à partir de la surface à isoler et non la surface habitable. Veuillez saisir la surface en m²",
    items: [
      {
        label: "Surface (m²)",
        name: "Q44",
        placeholder: "150",
        type: "number",
        min: 10,
        step: 10,
      },
    ],
  },

  Q45: {
    greeting: "Vous avez un devis pour quel type de travaux ?",
    items: [
      [
        // { short: "Isolation des combles", long: "Des combles" },
        // { short: "Isolation des murs", long: "Des murs" },
        { short: "Fenêtre", long: "Des fenêtres" },
        // { short: "Isolation d'un plancher", long: "Un vide sanitaire, plancher bas ou sous sol" },
      ],
      [
        { short: "PAC eau/eau", long: "Pompe à chaleur eau/eau" },
        { short: "Poêle à bois", long: "Poêle à bois" },
        { short: "PAC air/eau", long: "Pompe à chaleur air/eau" },
        // { short: "Réseau de chaleur", long: "Raccordement à un réseau de chaleur" },
        {
          short: "PAC hybride air/eau",
          long: "Pompe à chaleur hybride air/eau",
        },
        { short: "PAC air/air", long: "Pompe à chaleur air/air" },
        {
          short: "Chaudière à granulé de bois",
          long: "Chaudière biomasse (bois)",
        },
        // { short: "Poêle à granulé de bois", long: "Poêle à granulé de bois" },
        { short: "Système solaire combiné", long: "Système solaire combiné" },
        // { short: "Chaudière gaz", long: "Chaudière à gaz" },
      ],
      [
        {
          short: "Chauffe-eau thermodynamique",
          long: "Chauffe-eau thermodynamique",
        },
        // { short: "Chauffe-eau solaire individuel", long: "Chauffe-eau solaire individuel (ECS)" },
      ],
      // [
      //     { short: "VMC simple flux", long: "VMC Simple flux" },
      //     { short: "VMC double flux", long: "VMC double flux" },
      // ],
      // [
      //     { short: "Autre", long: "Autre travaux" },
      // ]
    ],
  },

  Q46: {
    greeting: "Combien de fenêtres souhaitez-vous installer ?",
    items: [
      {
        label: "",
        name: "Q46",
        placeholder: "3",
        type: "number",
        min: 1,
        step: 1,
      },
    ],
  },

  Q47: {
    greeting: "Quelle est la surface chauffée ?",
    paragraph: "Veuillez saisir la surface en m²",
    items: [
      {
        label: "Surface (m²)",
        name: "Q47",
        placeholder: "150",
        type: "number",
        min: 5,
        step: 10,
      },
    ],
  },

  Q48: {
    greeting: "Pourquoi souhaitez-vous réaliser un audit énergétique ?",
    items: [
      {
        short: "Audit énergétique",
        long: "Audit incitatif",
        icon: auditA,
        img: incitatifPhoto,
        text: "Je souhaite un audit énergétique pour réaliser des travaux de rénovation énergétique et obtenir des aides",
      },
      {
        short: "Audit règlementaire",
        long: "Audit réglementaire",
        icon: auditB,
        img: reglementairePhoto,
        text: "Je dois réaliser un audit énergétique pour vendre mon bien classé F ou G au DPE",
      },
    ],
  },
  Q49: {
    greeting: "Quel type de fenêtre souhaitez-vous installer ?",
    items: [
      {
        short: "Portes-fenêtres",
        long: "Fenêtres ou Portes-fenêtres complètes avec vitrage isolant",
        icon: fenetreA,
      },
      { short: "Fenêtres", long: "Fenêtres de toitures", icon: fenetreB },
    ],
  },
  Q50: {
    greeting: "Souhaitez-vous remplacer votre mode de chauffage actuel ?",
    items: [
      { short: "Oui", long: "Oui", icon: oui },
      { short: "Non", long: "Non", icon: non },
    ],
  },

  Q51: {
    greeting: "Quel est l'usage de votre pompe à chaleur ?",
    items: [
      { short: "Chauffage seul", long: "Chauffage uniquement", icon: gaz1 },
      {
        short: "Chauffage + Eau Chaude Sanitaire",
        long: "Chauffage et eau chaude sanitaire",
        icon: gaz2,
      },
    ],
  },

  OptimhomeAgent: {
    greeting: "Quelle est votre adresse e-mail Optimhome ?",
    paragraph: "Exemple : jean.dubois@optimhome.com",
    items: [
      {
        label: "E-mail",
        name: "partenaireEmail",
        placeholder: "jean.dubois@optimhome.com",
        type: "email",
        validation: "email",
      },
    ],
  },

  OptimhomeClient: {
    greeting: "Indiquez l'adresse e-mail de votre conseiller Optimhome",
    paragraph: "Exemple : jean.dubois@optimhome.com",
    items: [
      {
        label: "E-mail",
        name: "partenaireEmail",
        placeholder: "jean.dubois@optimhome.com",
        type: "email",
        validation: "email",
      },
    ],
  },

  CapifranceAgent: {
    greeting: "Quelle est votre adresse e-mail Capifrance ?",
    paragraph: "Exemple : jean.dubois@capifrance.com",
    items: [
      {
        label: "E-mail",
        name: "partenaireEmail",
        placeholder: "jean.dubois@capifrance.com",
        type: "email",
        validation: "email",
      },
    ],
  },

  CapifranceClient: {
    greeting: "Indiquez l’adresse e-mail de votre conseiller Capifrance ?",
    paragraph: "Exemple : jean.dubois@capifrance.com",
    items: [
      {
        label: "E-mail",
        name: "partenaireEmail",
        placeholder: "jean.dubois@capifrance.com",
        type: "email",
        validation: "email",
      },
    ],
  },

  LaforetAgent: {
    greeting: "Quelle est votre agence Laforêt ?",
    paragraph:
      "Pour faciliter votre recherche, veuillez saisir votre code agence(exemple: A84)",
    items: [
      {
        label: "E-mail",
        name: "partenaireEmail",
        placeholder: "jean.dubois@capifrance.com",
        type: "email",
        validation: "email",
      },
    ],
  },

  LaforetClient: {
    greeting: "Dans quelle agence Laforêt êtes-vous client ?",
    paragraph:
      "Pour faciliter votre recherche, veuillez saisir votre code agence (exemple: A84)",
    // 'items': [
    //     { label: 'E-mail', name: "partenaireEmail", placeholder: 'jean.dubois@capifrance.com', type:'email', validation: 'email'},
    // ],
  },
  Success: {
    greeting: "Success",
  },

  CreneauPicker: {
    creneau: [
      { label: "Creneau", name: "Creneau", value: "Matin" },
      { label: "Creneau", name: "Creneau", value: "Après-midi" },
    ],
  },
};

export default dataItems;
