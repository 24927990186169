import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Navigation from "components/Navigation";
import Autocomplete from "react-google-autocomplete";
import Pays from "data/pays.json";

const PaysCodes = Object.fromEntries(
  Object.entries(Pays).map(([code, nom]) => [nom, code]),
);

function focusAddressField() {
  document.querySelectorAll("input[type=text]")[0].focus();
}

function validatePostalCodeFR(value) {
  const Reg = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;
  return Reg.test(value);
}

const IDFlist = ["75", "77", "78", "91", "92", "93", "94", "95"];
function isIDF(postalCode) {
  return IDFlist.includes(postalCode.slice(0, 2));
}

function QuestionsTextAddress({
  international = false,
  userContact,
  handleAddress,
  data,
  step,
  nextStep,
  lastStep,
}) {
  const [address, setAddress] = useState({
    residence:
      !userContact?.country || userContact?.country === "France"
        ? "France"
        : "Étranger",
    country: PaysCodes[userContact?.country] ?? "FR",
    street: userContact?.street ?? "",
    city: userContact?.city ?? "",
    postalcode: userContact?.postalcode ?? "",
    state: userContact?.state ?? "",
  });
  const [showAddress, setShowAddress] = useState(true);
  const [showAllFields, setShowAllFields] = useState(!!userContact?.street);

  useEffect(focusAddressField, []);

  const saveAddress = (newAddress) => {
    setAddress(newAddress);

    const isFrance = newAddress.residence === "France";
    const country = isFrance ? "France" : Pays[newAddress.country];

    const isValid =
      country &&
      newAddress.city &&
      newAddress.street &&
      (country === "France"
        ? validatePostalCodeFR(newAddress.postalcode)
        : newAddress.postalcode && newAddress.state);

    handleAddress({
      isValid,
      country,
      street: newAddress.street,
      city: newAddress.city,
      postalcode: newAddress.postalcode,
      state: newAddress.state,
      isIDF: isFrance && isIDF(newAddress.postalcode),
    });
  };

  const setFormValue = (event) => {
    const newAddress = { ...address };
    const field = event.target.name;
    const value = event.target.value;

    newAddress[field] = value;

    if (field === "residence") {
      setShowAddress(value === "France");
      setShowAllFields(false);
      newAddress.street = "";
      newAddress.city = "";
      newAddress.postalcode = "";
      newAddress.state = "";
      newAddress.country = "";
    }

    if (field === "country" && value !== "") {
      setShowAddress(true);
    }

    if (newAddress.residence === "France") {
      newAddress.country = "FR";
      newAddress.state = "";
    }

    saveAddress(newAddress);

    if (field === "country") {
      setTimeout(focusAddressField, 0);
    }
  };

  const onPlaceSelected = (place) => {
    const addressComponents = place.address_components;

    const findComponent = (type) =>
      addressComponents.find((component) => component?.types?.includes(type));

    const streetNumber = findComponent("street_number")?.long_name;
    const streetName = findComponent("route")?.long_name;
    const street = `${streetNumber ?? ""} ${streetName ?? ""}`.trim();
    const city = findComponent("locality")?.long_name;
    const state = findComponent("administrative_area_level_1")?.long_name;
    const country = findComponent("country")?.short_name;
    const postalcode = findComponent("postal_code")?.long_name;

    saveAddress({
      ...address,
      residence: country === "FR" ? "France" : "Étranger",
      street,
      city,
      state,
      country,
      postalcode,
    });
    setShowAllFields(true);
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter" && data) {
      nextStep();
    }
  };

  const isFrance = address.residence === "France";
  const labelStyle =
    isFrance || !showAllFields ? { width: "90px" } : { width: "180px" };

  return (
    <div className="fadeIn">
      <div className="w-100 d-flex jcc mt-20">
        <div style={{ minWidth: "360px" }} className="d-flex column">
          <div className="mb-50">
            {international && (
              <div className="input-container flex-input" key="residence">
                <label className="mt-10" style={labelStyle}></label>
                <div className="mv-5 h-8">
                  <label className="cursor-pointer">
                    France
                    <input
                      type="radio"
                      value="France"
                      name="residence"
                      checked={address.residence === "France"}
                      onChange={setFormValue}
                      className="ml-10 radio-align cursor-pointer"
                    />
                  </label>
                  <label className="cursor-pointer ml-8">
                    Étranger
                    <input
                      type="radio"
                      value="Étranger"
                      name="residence"
                      checked={address.residence === "Étranger"}
                      onChange={setFormValue}
                      className="ml-10 radio-align cursor-pointer"
                    />
                  </label>
                </div>
              </div>
            )}
            {!isFrance && (
              <div className="input-container flex-input" key="country">
                <label className="mt-10" style={labelStyle}>
                  Pays
                </label>
                <div className="mv-5">
                  <select
                    name="country"
                    value={address.country}
                    onChange={setFormValue}
                    className="align-middle"
                  >
                    {[
                      "",
                      ...Object.keys(Pays).filter((code) => code !== "FR"),
                    ].map((code) => (
                      <option key={code} value={code}>
                        {Pays[code] ?? "Sélectionner un pays"}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {showAddress && (
              <div className="input-container flex-input" key="street">
                <label className="mt-10" style={labelStyle}>
                  Adresse
                </label>
                <Autocomplete
                  apiKey="AIzaSyAhT809cnid2Uqamc6Gk_6j-QP-oZuSqj0"
                  options={{
                    types: ["address"],
                    componentRestrictions: {
                      country: address.country.toLowerCase(),
                    },
                  }}
                  onPlaceSelected={onPlaceSelected}
                  name="street"
                  type="text"
                  data-name="street"
                  onChange={setFormValue}
                  value={address.street}
                  placeholder={
                    isFrance ? "113 rue du soiemont" : "1337 sunset blvd"
                  }
                  className="mv-5"
                />
              </div>
            )}
            {showAllFields && !isFrance && (
              <div className="input-container flex-input" key="state">
                <label className="mt-10" style={labelStyle}>
                  État / Province / Région
                </label>
                <input
                  type="text"
                  placeholder="CA"
                  name="state"
                  onChange={setFormValue}
                  value={address.state}
                  onKeyUp={onKeyPress}
                  className="mv-5"
                />
              </div>
            )}
            {showAllFields && (
              <div className="input-container flex-input" key="postalcode">
                <label className="mt-10" style={labelStyle}>
                  {isFrance ? "Code postal" : "Code postal / ZIP code"}
                </label>
                <input
                  type="text"
                  placeholder={isFrance ? "51290" : "90210"}
                  name="postalcode"
                  onChange={setFormValue}
                  value={address.postalcode}
                  maxLength={isFrance ? 5 : null}
                  minLength={isFrance ? 5 : null}
                  onKeyUp={onKeyPress}
                  className="mv-5"
                />
              </div>
            )}
            {showAllFields && (
              <div className="input-container flex-input" key="city">
                <label className="mt-10" style={labelStyle}>
                  Ville
                </label>
                <input
                  type="text"
                  placeholder={
                    isFrance
                      ? "Saint-Remy-en-Bouzemont-Saint-Genest-et-Isson"
                      : "Beverly Hills"
                  }
                  name="city"
                  onChange={setFormValue}
                  value={address.city}
                  onKeyUp={onKeyPress}
                  className="mv-5"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <Navigation
        data={data}
        step={step}
        nextStep={nextStep}
        lastStep={lastStep}
      />
    </div>
  );
}
QuestionsTextAddress.propTypes = {
  international: PropTypes.bool,
  userContact: PropTypes.any.isRequired,
  handleAddress: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  step: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  lastStep: PropTypes.func.isRequired,
};

export default QuestionsTextAddress;
